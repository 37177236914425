/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import GlobalStyle from 'src/components/view/global-style'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.min.css'

function Layout({ children }) {
  return (
    <>
      <GlobalStyle />
      <main className="bg-dark">{children}</main>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,
     user-scalable=0"
        />
      </Helmet>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
