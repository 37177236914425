import React from 'react'
import { Global, css } from '@emotion/core'

function GlobalStyle() {
  return (
    <Global
      styles={css`
        :root {
          --main-bg-color: rgba(0, 0, 0, 0.75);
          --main-text-color: white;
        }
        ._theme-bg {
          background-color: var(--main-bg-color);
        }
        html,
        #___gatsby,
        #gatsby-focus-wrapper,
        main,
        main > div,
        body {
          height: 100%;
        }
        main > div {
          display: flex;
          flex-direction: column;
        }
        ._button-opacity {
          opacity .75;
        }
      `}
    />
  )
}

export default GlobalStyle
